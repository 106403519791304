import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/react"

const EbooksSuggestions = () => {
  const data = useStaticQuery(graphql`
    {
      allMdx(filter: { frontmatter: { ispublished: { eq: true } } }) {
        edges {
          node {
            id
            frontmatter {
              id
              slug
              ebook_image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // Function that filter current ebook from allEbooks list then slice the array to keep it to 3 items max
  const suggestions = () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("currentEbook")) {
        return data.allMdx.edges
          .filter(
            ({ node }) =>
              node.id !== JSON.parse(localStorage.getItem("currentEbook")).id
          )
          .slice(0, 3)
      } else {
        return data.allMdx.edges
      }
    } else {
      return data.allMdx.edges
    }
  }

  return (
    <div css={EbooksSuggestionsStyles}>
      <div id="grid">
        {suggestions().map(({ node }) => (
          <div key={node.frontmatter.id}>
            {" "}
            <Link to={`/free-guides/${node.frontmatter.slug}`}>
              <Img
                fluid={node.frontmatter.ebook_image.childImageSharp.fluid}
                alt="ebook"
              />
              <h4>
                Download
                <svg className="arrow-icon">
                  <use href="/icons-sprite-map.svg#down-arrow-icon" />
                </svg>
              </h4>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EbooksSuggestions

const EbooksSuggestionsStyles = css`
  #grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-areas: "a a a";
    grid-gap: 10px;
    margin: auto;
    margin-top: 50px;
    justify-content: center;
    max-width: 1200px;
    a {
      text-decoration: none;
      color: black;
    }
  }

  #grid > div {
    text-align: center;
  }

  .arrow-icon {
    width: 0.8rem;
    height: 0.8rem;
    fill: #0c0c3d;
    margin-left: 5px;
    border: 1px solid black;
    border-radius: 100px;
  }

  @media screen and (max-width: 600px) {
    #grid {
      grid-template-areas: "a";
    }
  }
`
