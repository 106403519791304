import React from "react"
import "../assets/styles/global.css"

import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"
// import Rgpd from "../components/Bar/rgpd"
import BasicLayout from "../layouts/BasicLayout"
import EbooksSuggestions from "../components/Content/Ebooks/EbooksSuggestions"
import { SEO_OPTIONS_THANK_YOU } from "../const/SEOOptions"

const HangOnPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "main-logo-blue.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <BasicLayout
    SEOOptions={SEO_OPTIONS_THANK_YOU}
    >
    <div css={HangOnPageStyles}>
      <div className="header">
        <div className="logo">
          <Link to="/">
            <Img
            fluid={data.logo.childImageSharp.fluid}
            alt="Focus Datascience logo"
            placeholderStyle={{ backgroundColor: `white` }}
          />
          </Link>
          
        </div>
        <h1 className="title-1">Thank you!</h1>
        {location?.state?.fromMeeting && (
          <h1 className="title-2">
            <span role="img" aria-label="check-emoji">
              ✅
            </span>{" "}
            Your meeting is scheduled
          </h1>
        )}
        <h1 className="title-2">
          <span role="img" aria-label="check-emoji">
            ✅
          </span>{" "}
          Your ebook is open in a new tab
        </h1>
      </div>
      <EbooksSuggestions />
      <div className="links">
        <Link to={"/"}>OUR SERVICES</Link>
        <Link to={"/free-guides/"}>SEE ALL RESSOURCES</Link>
      </div>
      {/* <Rgpd location={location}/> */}
    </div>
    </BasicLayout>
  )
}

export default HangOnPage

const HangOnPageStyles = css`
  .header {
    grid-row: 1;
    text-align: center;

    margin-bottom: 80px;
  }

  .logo {
    width: 11.52rem;
    display: block;
    margin: auto;
  }

  .title-1 {
    font-size: 50px;
    color: #133c9e;
  }

  .title-2 {
    font-size: 30px;
  }

  .links {
    margin-top: 50px;
    margin-bottom: 80px;
    text-align: center;
    a {
      display: inline-grid;
      align-content: center;
      text-decoration: none;
      height: 60px;
      width: 250px;    
      background-color: #133c9e;
      color: white;
      font-weight: 600;
      cursor: pointer;
      border-radius: 10px;
      margin-right: 10px;
      margin-top: 30px;
      text-align: center;
    }

    a:first-of-type {
      box-shadow: 5px 8px 8px rgb(0 0 0 / 20%);
    }

    a:last-of-type {
      color: #133c9e;
      background-color: white;
      border: 2px solid #133c9e;
    }
  }

  @media screen and (max-width: 600px) {
    button,
    a {
      width: 250px;
      margin: 10px auto;
    }

    .buttons {
      display: grid;
      padding-bottom: 50px;
    }
  }
`
